import Main from "./pages/Home/Main";
import AboutMain from "./pages/About/AboutMain";
import { BrowserRouter as Router,Routes,Route } from "react-router-dom";
import ContactMain from "./pages/Contact/ContactMain";
import ProductMain from "./pages/Product/ProductMain";
import CareerMain from "./pages/Careers/CareerMain";
import ErrorMain from "./pages/Error Page/ErrorMain";
import Aiml from "./pages/Services/TechServices/AIML";
import DataServices from "./pages/Services/TechServices/DataServices";
import Cloud from "./pages/Services/TechServices/Cloud";
import IndustryMain from "./pages/Industries/IndustryMain";
import ClientMain from "./pages/Clients/ClientMain";
import ClientDetailsMain from "./pages/Clients/Client-details/Client-detailsMain";
import './index.css';
function App() {
  return (
    <Router>
       <Routes>
         <Route exact path="/" element={<Main/>}/>
         <Route exact path="/about" element={<AboutMain/>}/>
         <Route exact path="/contact" element={<ContactMain/>}/>
         <Route exact path="/aiml" element={<Aiml />} />
         <Route exact path="/dataservice" element={<DataServices />} />
         <Route exact path="/cloud" element={<Cloud />} />
         <Route exact path="/product" element={<ProductMain/>}/>
         <Route exact path="/careers" element={<CareerMain/>}/>
         <Route exact path="/clients" element={<ClientMain/>}/>
         <Route exact path="/client/client-details" element={<ClientDetailsMain/>}/>
         <Route exact path="/businessservices" element={<IndustryMain/>}/>
         <Route exact path="*" element={<ErrorMain/>}/>
         
         
       </Routes>
    </Router>
  );
}

export default App;
