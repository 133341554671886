import React from 'react';
import BannerImage from '../../../assets/img/bannr.png';
import { Link } from 'react-router-dom';

function ClientBanner() {
  return (
    <section
    className="bannr">
   <div className="container">
      <div className="row align-items-end">
        <div className="col-lg-6">
          <div className="bannr-text">
            <h2>Project Details</h2>
            <p>
            We help develop solutions for you to solve your business problems with innovation, cutting edge technology which are robust & scalable.
            </p>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li  className="breadcrumb-item active" aria-current="page">
                <Link to="/clients">Clients</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Clients-details
              </li>
            </ol>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="bannr-img">
            <img src={BannerImage} alt="img" />
          </div>
        </div>
      </div>
    </div>
  </section>
  
  )
}

export default ClientBanner;