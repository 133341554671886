import React from 'react'

const SubServiceTitle = ({title}) => {
  return (
    <div className="row">
        <div className="col-lg-12">
          <div className="heading two mt-5">
            <h2 className='text-center'>{title}</h2>
          </div>
        </div>
      </div>
  )
}

export default SubServiceTitle
