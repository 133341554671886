import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../assets/img/logo.png";
import mobilelogo from "../assets/img/mobilelogo.png";

function Header() {
  const location = useLocation();
  const [isMobileNavVisible, setMobileNavVisible] = useState(false);
  const [servicesActive, setServicesActive] = useState(false);
  const [techServicesActive, setTechServicesActive] = useState(false);
  const [productActive, setProductActive] = useState(false);

  const handleServicesClick = () => {
    setServicesActive(!servicesActive);
    setProductActive(false); // Close product dropdown if open
    setTechServicesActive(false); // Close product dropdown if open
  };

  const handleProductClick = () => {
    setProductActive(!productActive);
    setServicesActive(false);
    setTechServicesActive(false); // Close services dropdown if open
  };

  const handleTechServicesClick = () => {
    setTechServicesActive(!techServicesActive);
    setProductActive(false);
    setServicesActive(false); // Close product dropdown if open
  };


  // Function to toggle the mobile nav visibility
  const toggleMobileNav = () => {
    setMobileNavVisible(!isMobileNavVisible);
  };

  const [isActive, setIsActive] = useState(false);

  const handleItemClick = () => {
    setIsActive(!isActive);
  };

  const closeDropdown = () => {
    setIsActive(false);
  };

  return (
    <header className="topnav">
      <div className="container">
        <div className="header-bar">
          <a href="/">
            <img src={logo} alt="logo" style={{ width: "130px" }} />
          </a>
          <nav className="navbar">
            <ul className="navbar-links">
              <li
                className={`navbar-dropdown ${location.pathname === "/" ? "active" : ""
                  }`}
              >
                <Link to="/">Home</Link>
              </li>
              <li
                className={`navbar-dropdown has dropdown  ${location.pathname === "/businessservices" ? "active" : ""
                  }`}
              >
                <Link to="/businessservices">Business Industries</Link>
                <ul className="dropdown">
                  <li>
                    <Link to="/businessservices">
                      <i className="fa-solid fa-arrow-right" />
                      Healthcare
                    </Link>
                  </li>
                  <li>
                    <Link to="/businessservices">
                      <i className="fa-solid fa-arrow-right" />
                      Banking & Financial
                    </Link>
                  </li>
                  <li>
                    <Link to="/businessservices">
                      <i className="fa-solid fa-arrow-right" />
                      Telecom & Media
                    </Link>
                  </li>
                  <li>
                    <Link to="/businessservices">
                      <i className="fa-solid fa-arrow-right" />
                      Industrial
                    </Link>
                  </li>
                </ul>
              </li>
              <li
                className={`navbar-dropdown has dropdown  ${location.pathname === "/techservices" ? "active" : ""
                  }`}
              >
                <Link to="#">Tech Services</Link>
                <ul className="dropdown">
                  <li>
                    <Link to="/dataservice">
                      <i className="fa-solid fa-arrow-right" />
                      Data Services
                    </Link>
                  </li>
                  <li>
                    <Link to="/cloud">
                      <i className="fa-solid fa-arrow-right" />
                      Cloud Services
                    </Link>
                  </li>
                  <li>
                    <Link to="/aiml">
                      <i className="fa-solid fa-arrow-right" />
                      Ai & ML
                    </Link>
                  </li>
                </ul>
              </li>
              <li
                className={`navbar-dropdown has-children ${location.pathname === "/product" ? "active" : ""
                  }`}
              >
                <Link to="/product">Product</Link>
                <ul className="dropdown">
                  <li>
                    <Link to="/product">
                      <i className="fa-solid fa-arrow-right" />
                      Nove - Intelligent Analys
                    </Link>
                  </li>
                  <li>
                    <Link to="/product">
                      <i className="fa-solid fa-arrow-right" />
                      IT Sight CRM
                    </Link>
                  </li>
                  <li>
                    <Link to="/product">
                      <i className="fa-solid fa-arrow-right" />
                      Appointment Booking System
                    </Link>
                  </li>
                  <li>
                    <Link to="/product">
                      <i className="fa-solid fa-arrow-right" />
                      Bulk Meassage Sender{" "}
                    </Link>
                  </li>
                </ul>
              </li>
              <li className={isActive === "client" ? "active" : ""}>
              <Link to="/clients" onClick={() => handleItemClick("client")}>
                Clients
              </Link>
            </li>
              <li
                className={`navbar-dropdown ${location.pathname === "/careers" ? "active" : ""
                  }`}
              >
                <Link to="/careers">Careers</Link>
              </li>
              <li
                className={`navbar-dropdown ${location.pathname === "/about" ? "active" : ""
                  }`}
              >
                <Link to="/about">About</Link>
              </li>
              {/*<li className="navbar-dropdown">
            <a href="faqs.html">Faq’s</a>
          </li> 
          <li className="navbar-dropdown">
          <Link to='/contact'>Contact</Link>
          </li>*/}
            </ul>
          </nav>
          <div className="pickup">
            <div className="menu-icon cursor-pointer" onClick={toggleMobileNav}>
              <span className="text">
                <span className="word">Menu</span>
              </span>
              <span className="icon">
                <i />
                <i />
              </span>
            </div>

            {/* <a href="/contact" className="btn">
              Contact Us
            </a> */}
            <Link to="/contact" className="btn">
              Contact Us
            </Link>
          </div>
          <div className="bar-menu" onClick={toggleMobileNav}>
            <i className="fa-solid fa-bars" />
          </div>
        </div>
        <div
          className={`mobile-nav hmburger-menu ${isMobileNavVisible ? "open" : ""
            }`}
          id="mobile-nav"
          style={{
            display: isMobileNavVisible ? "block" : "none",
          }}
        >
          <div className="res-log">
            <a href="/">
              <img
                src={mobilelogo}
                alt="Responsive Logo"
                style={{ width: "130px" }}
              />
            </a>
          </div>
          <ul>
            <li className={isActive === "home" ? "active" : ""}>
              <Link to="/" onClick={() => handleItemClick("home")}>
                Home
              </Link>
            </li>
            <li className={`menu-item-has-children ${servicesActive ? "active" : ""}`}>
              <a href="/businessservices" onClick={(e) => { e.preventDefault(); handleServicesClick(); }}>
                Business Industries<i className="fa-solid fa-angle-right" />
              </a>
              {servicesActive && (
                <ul className="sub-menu">
                  <li><Link to="/businessservices">Healthcare</Link></li>
                  <li><Link to="/businessservices">Banking & Financial</Link></li>
                  <li><Link to="/businessservices">Telecom & Media</Link></li>
                  <li><Link to="/businessservices">Industrial</Link></li>
                </ul>
              )}
            </li>
            <li className={`menu-item-has-children ${techServicesActive ? "active" : ""}`}>
              <a href="/techservices" onClick={(e) => { e.preventDefault(); handleTechServicesClick(); }}>
                Tech Services<i className="fa-solid fa-angle-right" />
              </a>
              {techServicesActive && (
                <ul className="sub-menu">
                  <li><Link to="/dataservice">Data Services</Link></li>
                  <li><Link to="/cloud">Cloud Services</Link></li>
                  <li><Link to="/aiml">Ai & ML</Link></li>
                </ul>
              )}
            </li>
            <li className={`menu-item-has-children ${productActive ? "active" : ""}`}>
              <a href="/product" onClick={(e) => { e.preventDefault(); handleProductClick(); }}>
                Product <i className="fa-solid fa-angle-right" />
              </a>
              {productActive && (
                <ul className="sub-menu">
                  <li><Link to="/product">Nove - Intelligent Analys</Link></li>
                  <li><Link to="/product">IT Sight CRM</Link></li>
                  <li><Link to="/product">Appointment Booking System</Link></li>
                  <li><Link to="/product">Bulk Message Sender</Link></li>
                </ul>
              )}
            </li>
            <li className={isActive === "client" ? "active" : ""}>
              <Link to="/clients" onClick={() => handleItemClick("client")}>
                Clients
              </Link>
            </li>

            <li className={isActive === "about" ? "active" : ""}>
              <Link to="/careers" onClick={() => handleItemClick("about")}>
                Careers
              </Link>
            </li>

            <li className={isActive === "about" ? "active" : ""}>
              <Link to="/about" onClick={() => handleItemClick("about")}>
                About
              </Link>
            </li>
          </ul>
          <div className="menu-sidebar-single-widget">
            <h5 className="menu-sidebar-title">Contact Info</h5>
            <div className="header-contact-info">
              <span>
                <i className="fa-solid fa-location-dot" />
                Magarpatta City, Pune, MH, 411028, India.
              </span>
              <span>
                <a href="mailto:contact@itsight.in">
                  <i className="fa-solid fa-envelope" />
                  contact@itsight.in
                </a>
              </span>
              <span>
                <a href="https://chat.whatsapp.com/Ir5alYhx1I56zATOQY09oX">
                  <i className="fa-brands fa-square-whatsapp" />
                  Contact Us
                </a>
              </span>
            </div>
            <div className="social-profile">
              <a href="https://www.facebook.com/itsightin">
                <i className="fa-brands fa-facebook-f" />
              </a>
              <a href="https://twitter.com/itsightin">
                <i className="fa-brands fa-twitter" />
              </a>
              <a href="https://www.linkedin.com/company/itsightin/">
                <i className="fa-brands fa-linkedin-in" />
              </a>
              <a href="https://www.youtube.com/@itsightin">
                <i className="fa-brands fa-youtube" />
              </a>
            </div>
          </div>
          <a
            href="JavaScript:void(0)"
            id="res-cross"
            onClick={() => setMobileNavVisible(false)}
          >
            <i className="fa-regular fa-circle-xmark" />
          </a>
        </div>
      </div>
      <div className="hamenu valign">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 valign">
              <div className="cont-info two">
                <div className="about-sasnio bottom">
                  <img src="assets/img/about-immg.jpg" alt="about-immg" />
                  <h4>About IT Sight Company</h4>
                  <p>
                    At Itsight, we are revolutionizing the way businesses
                    harness the power of data, cloud, and AI. Our cutting-edge
                    solutions enable organizations to unlock valuable insights,
                    streamline processes, and drive innovation.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 valign">
              <div className="cont-info">
                <div className="item mb-50">
                  <h6 className="text-u fw-600 mb-20">Location</h6>
                  <p className="fw-400 fz-18">
                    Magarpatta City, Pune, MH, 411028, India.
                  </p>
                </div>
                <div className="item mb-50">
                  <h6 className="text-u fw-600 mb-20">HOW TO CONNECT</h6>
                  <p className="fw-400 fz-18">
                    <a href="https://chat.whatsapp.com/Ir5alYhx1I56zATOQY09oX">
                      <i className="fa-brands fa-square-whatsapp" />
                      Contact Us
                    </a>
                  </p>
                  <p className="fw-400 fz-18">
                    <a href="mailto:contact@itsight.in">contact@itsight.in</a>
                  </p>
                </div>
                <div className="bottom">
                  <h6 className="text-u fw-600 mb-20">Follow Us</h6>
                  <ul className="rest social-text d-flex fz-13">
                    <li>
                      <a href="https://www.facebook.com/itsightin">
                        <i className="fa-brands fa-facebook-f" />
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com/itsightin">
                        <i className="fa-brands fa-twitter" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/company/itsightin/">
                        <i className="fa-brands fa-linkedin-in" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.youtube.com/@itsightin">
                        <i className="fa-brands fa-youtube" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
