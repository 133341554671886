import React from 'react';
import Header from '../../component/Header';
import Hero from './Hero';
import Client from './Client';
import Sustainability from './Sustainability';
import Footer from '../../component/Footer';
import Project from './Project';
import Testimonial from './Testimonial';
import News from './News';
import Touch from './Touch';
// import Application from './Application';
import ServiceCards from './ServiceCards';
function Main() {
  return (
    <div>
        <Header />
        <Hero />
        <ServiceCards />
        <Sustainability />
        <Client />
        <Project />
        <Testimonial />
        <Touch />
        <Footer />
    </div>
  )
}

export default Main
