import React from 'react';
import headimg from '../../assets/img/heading-img.png';
import blog1 from '../../assets/img/blog-1.jpg';
import blog2 from '../../assets/img/blog-2.jpg';
import blog3 from '../../assets/img/blog-3.jpg';
import shape1 from '../../assets/img/shaps.png';

function News() {
  return (
    <section className="gap blog-section">
  <div className="container">
    <div className="heading">
      <img src={headimg} alt="heading-img" />
      <h6>Tips and advice</h6>
      <h2>Our Latest News</h2>
    </div>
    <div className="row">
      <div className="col-lg-4 col-md-6">
        <div className="blog img-hover ">
          <div className="blog-img">
            <figure>
              <img src={blog1} alt="blog" />
            </figure>
          </div>
          <h5>
            23<span>May,2024</span>
          </h5>
          <a href="blog-details.html">
            <h4>How to build the Best IT Solutions Company</h4>
          </a>
          <h6>
            by <b>Thomas Walimes</b>
          </h6>
        </div>
      </div>
      <div className="col-lg-4 col-md-6">
        <div className="blog img-hover">
          <div className="blog-img">
            <figure>
              <img src={blog2} alt="blog" />
            </figure>
          </div>
          <h5>
            23<span>May,2024</span>
          </h5>
          <a href="blog-details.html">
            <h4>How to get a job in digital marketing</h4>
          </a>
          <h6>
            by <b>Thomas Walimes</b>
          </h6>
        </div>
      </div>
      <div className="col-lg-4 col-md-6">
        <div className="blog img-hover mb-0">
          <div className="blog-img">
            <figure>
              <img src={blog3} alt="blog" />
            </figure>
          </div>
          <h5>
            23<span>May,2024</span>
          </h5>
          <a href="blog-details.html">
            <h4>Hungry to learn and grow, we are open-minded</h4>
          </a>
          <h6>
            by <b>Thomas Walimes</b>
          </h6>
        </div>
      </div>
      <div className="button-center">
        <a href="our-blog.html" className="btn">
          View All News
        </a>
      </div>
    </div>
  </div>
  <img src={shape1} alt="dots-shaps" className="dots-shaps" />
</section>

  )
}

export default News
