import React from 'react';
import SubServiceTitle from './SubServiceTitle';
import SubServiceCards from './SubServiceCards';
import backgroundP from '../../assets/img/background-p.png';

const SubServices = ({ services }) => {
  return (
    <div className="container">
      {services.map(service => (
        <div key={service.id}>
          <SubServiceTitle title={service.name} />
          {service.details && (
            <div className="row pt-5 m-auto">
              {service.details.map(detail => (
                <SubServiceCards
                  key={detail.detailId}
                  title={detail.title}
                  description={detail.description}
                  backgroundImage={backgroundP}
                  avatarImage={detail.imgurl}
                />
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default SubServices;
