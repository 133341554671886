import React from "react";
import Header from "../../component/Header";
import Footer from "../../component/Footer";
import Career from "./Career";
import Job from "./Job";
import "./job.css";


function CareerMain() {
  return (
    <div>
      <Header />
      <Career />
      <Job />

      <Footer />
    </div>
  );
}

export default CareerMain;
