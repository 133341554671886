import React from "react";
import susimg from "../../assets/img/propel.png";
import Businessicon from "../../assets/img/propel-icon-1.png";
import Trusticon from "../../assets/img/propel-icon-2.png";

function Sustainability() {
  return (
    <section>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="propel-img">
              <img src={susimg} alt="Propel" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="heading two">
              <h6> OUR APPROACH</h6>
              <h2>
              Our forward-thinking strategy integrates AI and ML at every stage, ensuring unparalleled innovation.
              </h2>
            </div>
            <div className="propel-text">
              <p>
                At ITsight, we start by understanding your challenges in
                our Business Challenge Review. Together, we create tailored
                solutions in Solutioning, fitting your specific needs. Then, our
                Business Implementation ensures seamless integration through
                phased testing. We're committed to guiding you towards success
                every step of the way.
              </p>
              <div className="propel">
                <div>
                  <i>
                    <img src={Businessicon} alt="propel-icon" />
                  </i>
                </div>
                <div>
                  <h5>Business Challenge Review </h5>
                  <p>
                    We help you realize the biggest challenges you're facing.
                  </p>
                </div>
              </div>
              <div className="propel">
                <div>
                  <i>
                    <img src={Businessicon} alt="propel-icon" />
                  </i>
                </div>
                <div>
                  <h5>Solutioning</h5>
                  <p>
                    Together, we come up with unique solutions which suit ypur use cases & problems.
                  </p>
                </div>
              </div>
              <div className="propel">
                <div>
                  <i>
                    <img src={Trusticon} alt="propel-icon" />
                  </i>
                </div>
                <div>
                  <h5>Business Implementation</h5>
                  <p>
                    Implementations often happens in phases paired with vigorous testing.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Sustainability;
