import React from 'react';
import Image from '../../assets/img/bannr.png'

function Banner() {
  return (
    <section
  className="bannr"
  
>
  <div className="container">
    <div className="row align-items-end">
      <div className="col-lg-6">
        <div className="bannr-text">
          <h2>Our Products</h2>
          <p>
            We create outstanding and world-class digital products, web design,
            apps, and branding.
          </p>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/">Home</a>
            </li>
            
            <li className="breadcrumb-item active" aria-current="page">
              Products
            </li>
          </ol>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="bannr-img">
          <img src={Image} alt="img" />
        </div>
      </div>
    </div>
  </div>
</section>

  )
}

export default Banner