import React from 'react';
import Header from '../../component/Header';
import Footer from '../../component/Footer';
import Banner from './Banner';
import ContactForm from './ContactForm';

function ContactMain() {
  return (
    <div>
    <Header/>
    <Banner/>
    <ContactForm/>
    <Footer/>
    
    </div>
  )
}

export default ContactMain