import React from "react";
import Intelligent from "../../assets/img/intelligent.png";
import Shape1 from "../../assets/img/shaps-1.png";
import IntelligentIcon1 from "../../assets/img/intelligent-icon-1.png";
import InteliigentIcon2 from "../../assets/img/intelligent-icon-2.png";
import Video from "../../assets/img/video.jpg";
import dot from "../../assets/img/dots-shaps.png";

function BussinessModel() {
  return (
    <section className="gap">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 mb-4 mb-lg-0">
            <div className="intelligent">
              <h2>About IT Sight</h2>
              <img src={Intelligent} alt="intelligent" />
              <img src={dot} alt="dots-shaps" className="dots-shaps" />
              <img src={Shape1} alt="dots-shaps" className="shaps-1" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="intelligent-text intelligent-text-first bg-light-gray p-4 rounded">
              <p>
                Welcome to IT Sight, where innovation meets expertise in Data,
                Cloud, and AI software solutions! As a pioneering leader in the
                industry, we empower businesses worldwide to harness the full
                potential of their data to drive growth and innovation. At IT
                Sight, we offer a comprehensive suite of products and services
                designed to streamline data management, optimize cloud
                infrastructure, and leverage cutting-edge AI technologies. Our
                diverse range of offerings caters to businesses of all sizes and
                across various industries, ensuring that each client receives
                tailored solutions to meet their unique needs and challenges.
              </p>
              <div className="row">
                <div className="col-lg-6">
                  <i>
                    <img src={IntelligentIcon1} alt="intelligent-icon" />
                  </i>
                  <a href="#">
                    <h4>Security Certified</h4>
                  </a>
                  <p>
                    Obtain the Security Certified credential to bolster your
                    expertise in safeguarding digital assets against
                    ever-evolving cyber threats.{" "}
                  </p>
                </div>
                <div className="col-lg-6">
                  <i className="two">
                    <img src={InteliigentIcon2} alt="intelligent-icon" />
                  </i>
                  <a href="#">
                    <h4>Cloud Compatibility</h4>
                  </a>
                  <p>
                    Enhance your security skills with our Cloud-Compatible
                    Security Certified credential, ensuring robust protection
                    for cloud-based environments against modern cyber threats.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-lg-6">
            <div className="intelligent-text bg-light-gray p-4 rounded">
              <p>
                Our Solutions Include: - <br />
                <b>Advanced AI Solutions:</b> Our AI-driven solutions harness
                the power of machine learning and natural language processing to
                extract actionable insights from vast datasets, enabling
                businesses to make informed decisions and gain a competitive
                edge. <br /> <b>Seamless Integration:</b> Our products are
                designed for seamless integration with existing systems and
                workflows, ensuring minimal disruption and maximum efficiency
                during implementation.
                <br /> <b> Expert Support:</b> Our team of experienced
                professionals provides dedicated support and guidance at every
                step of the journey, from initial consultation to ongoing
                maintenance, ensuring a smooth and successful deployment of our
                solutions. <br /> <b>Scalability and Flexibility:</b>
                Whether you're a startup looking to scale rapidly or an
                enterprise seeking to optimize resources, our solutions are
                built to grow with your business and adapt to evolving needs.{" "}
                <br />
                <b>Security and Compliance:</b> With robust security measures
                and adherence to industry best practices, we prioritize the
                protection of your data and ensure compliance with relevant
                regulations, providing peace of mind in an increasingly complex
                digital landscape. Partner with IT Sight and embark on a
                transformative journey towards data-driven success.
              </p>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="video position-relative">
              <a
                data-fancybox=""
                href="https://www.youtube.com/watch?v=xKxrkht7CpY"
              ></a>
              <img src={Video} alt="img" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BussinessModel;
