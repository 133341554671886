import React from "react";
import { useLocation } from "react-router-dom";
import Header from "../../../component/Header";
import Footer from "../../../component/Footer";
import Banner from "./Client-detailsBanner";
// import ClientDetails from "./Client-details";
import ProjectDetails from "./ProjectDetails";
function ClientDetailsMain() {
  const location = useLocation();
  const projectDetails = location.state; // Get the data passed via state

  return (
    <div>
      <Header />
      <Banner  />
      <ProjectDetails project={projectDetails} />
      {/* <ClientDetails  /> */}

      <Footer />
    </div>
  );
}

export default ClientDetailsMain;
