import React from "react";
import Header from "../../component/Header";
import Footer from "../../component/Footer";
import ClientBanner from "./ClientBanner";
import Client from "./Client";
// import Career from "./Career";
// import Job from "./Job";
// import "./job.css";

function CareerMain() {
  return (
    <div>
      <Header />
      <ClientBanner/>
      {/* <Job /> */}
      <Client/>
      <Footer />
    </div>
  );
}

export default CareerMain;
