import React , {useState} from "react";
import './../../assets/css/service.css';
import softwareproduct from './../../assets/img/service/softwareproduct.jpg';
import application from './../../assets/img/service/application.jpg';
import ai from './../../assets/img/service/AI.jpg';
import data from './../../assets/img/service/data.jpg';
import ml from './../../assets/img/service/machinelearning.jpg';
import cloud from './../../assets/img/service/cloud.jpg';

function ServiceCards() {

  const [expandedIndex, setExpandedIndex] = useState(null);

  const cardsData = [
    {
      image: data,
      title: "Data Services",
      description: "Discover the power of actionable insights at our website. Our tailored data analytics services."
    },
    {
      image: cloud,
      title: "Cloud Services",
      description: "Our team of skilled professionals leverages the latest tools and methodologies to deliver streamlined."
    },
    {
      image: ai,
      title: "AI & ML",
      description: "Our Expert Team is empowered with AI & ML, we build Next Gen softwares to be future ready."
    },
    // {
    //   image: application,
    //   title: "Application Development & Management",
    //   description: "Application Development & Management for websites involves the entire process of creating, enhancing."
    // },
    
    // {
    //   image: softwareproduct,
    //   title: "Software Product Engineering",
    //   description: "Discover excellence in software engineering on our website. Explore our expertise, optimize your."
    // },
   
    // {
    //   image: ml,
    //   title: "CX Transformation",
    //   description: "Developing and modernizing customer experiences to help you transform to a digital enterprise and accelerate business growth."
    // },
    // {
    //   image: cloud,
    //   title: "Cloud Services",
    //   description: "Our team of skilled professionals leverages the latest tools and methodologies to deliver streamlined."
    // },
    // Add more card data as needed
  ];

  const handleCardClick = (index) => {
    setExpandedIndex(index === expandedIndex ? null : index);
  };
  
  return (
    <section className="gap blog-section">
      <div className="container">
        <div className="heading">
          <img src={require("../../assets/img/fa.png")} alt="heading-img" />
          <h6>Solutions for all your needs</h6>
          <h2>Enjoy features built to enhance usability and provide meaningful solutions.</h2>
        </div>
        <ul className="cards">
          {cardsData.map((card, index) => (
            <li key={index} className={expandedIndex === index ? "expanded" : ""}>
              <a className="card" onClick={() => handleCardClick(index)}>
                <img src={card.image} className="card__image" alt="" />
                <div className="card__overlay">
                  <div className="card__header">
                    <svg className="card__arc" xmlns="http://www.w3.org/2000/svg"><path /></svg>
                    <div className="card__header-text">
                      <h3 className="card__title">{card.title}</h3>
                    </div>
                  </div>
                  <p className="card__description">{card.description}</p>
                </div>
              </a>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
}

export default ServiceCards;
