import React from "react";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/color.css";
import "../../assets/css/fontawesome.min.css";
import "../../assets/css/responsive.css";
import "../../assets/css/service.css";
import "../../assets/css/slick.css";
import "../../assets/css/style.css";
import project1 from "../../assets/img/projects-1.jpg";
import project2 from "../../assets/img/projects-2.jpg";
import project3 from "../../assets/img/projects-3.jpg";
import { useNavigate } from "react-router-dom";

const Client = () => {
  const navigate = useNavigate();

  const handleCardClick = (project) => {
    navigate("/client/client-details", { state: project });
  };

  const projects = [
    {
      id: 1,
      img: project2,
      title: "Doctor's Hub Pune",
      project_description: "A comprehensive web development project for a medical clinic, including an appointment management system for efficient patient handling.",
      category: "Website & Management System",
      year: 2024,
      service: "Web Development, System Integration",
      client: "Doctor's Hub Pune",
      duration: "Sept 2024 - Dec 2024",
      url: "https://doctorshubpune.in/",
      features: [
        {fet:"Far curiosity incommode now led smallness allowance."},
        {fet:"Far curiosity incommode now led smallness allowance."},
        {fet:"Far curiosity incommode now led smallness allowance."},
        {fet:"Far curiosity incommode now led smallness allowance."},
        {fet:"Far curiosity incommode now led smallness allowance."},
        {fet:"Far curiosity incommode now led smallness allowance."},
        {fet:"Far curiosity incommode now led smallness allowance."},
      ],
      whatWeProvide: [
        {wwp:"Application design and Development"},
        {wwp:"Application design and Development"},
        {wwp:"Application design and Development"},
        {wwp:"Application design and Development"},
        {wwp:"Application design and Development"},
        {wwp:"Application design and Development"},
        {wwp:"Application design and Development"},
      ],
      solAndRes: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",

    },
    {
      id: 2,
      img: project3,
      title: "Mahavir Steels Infra",
      project_description: "A professional website developed for a leading steel infrastructure company to showcase their projects and services.",
      category: "Corporate Website",
      year: 2024,
      service: "Web Development, SEO Optimization",
      client: "Mahavir Steels Infra",
      duration: "Oct 2024 - Ongoing",
      url: "https://mahavirsteelinfra.com/",
      features: [
        {fet:"Far curiosity incommode now led smallness allowance."},
        {fet:"Far curiosity incommode now led smallness allowance."},
        {fet:"Far curiosity incommode now led smallness allowance."},
        {fet:"Far curiosity incommode now led smallness allowance."},
        {fet:"Far curiosity incommode now led smallness allowance."},
        {fet:"Far curiosity incommode now led smallness allowance."},
        {fet:"Far curiosity incommode now led smallness allowance."},
      ],
      whatWeProvide: [
        {wwp:"Application design and Development"},
        {wwp:"Application design and Development"},
        {wwp:"Application design and Development"},
        {wwp:"Application design and Development"},
        {wwp:"Application design and Development"},
        {wwp:"Application design and Development"},
        {wwp:"Application design and Development"},
      ],
      solAndRes: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    },
    {
      id: 3,
      img: project2,
      title: "Inspire Coaching Academy",
      project_description: "An advanced website and management system for an academy, focusing on dynamic updates and efficient course handling.",
      category: "Website & Management System",
      year: 2024,
      service: "Web Development, Dynamic CMS",
      client: "Inspire Coaching Academy",
      duration: "Oct 2024 - Ongoing",
      url: "https://inspirecoaching.in/",
      features: [
        {fet:"Far curiosity incommode now led smallness allowance."},
        {fet:"Far curiosity incommode now led smallness allowance."},
        {fet:"Far curiosity incommode now led smallness allowance."},
        {fet:"Far curiosity incommode now led smallness allowance."},
        {fet:"Far curiosity incommode now led smallness allowance."},
        {fet:"Far curiosity incommode now led smallness allowance."},
        {fet:"Far curiosity incommode now led smallness allowance."},
      ],
      whatWeProvide: [
        {wwp:"Application design and Development"},
        {wwp:"Application design and Development"},
        {wwp:"Application design and Development"},
        {wwp:"Application design and Development"},
        {wwp:"Application design and Development"},
        {wwp:"Application design and Development"},
        {wwp:"Application design and Development"},
      ],
      solAndRes: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    },
    {
      id: 4,
      img: project3,
      title: "Pallash Boutique Store",
      project_description: "An elegant website developed for a boutique store to showcase their exclusive collection and drive online engagement.",
      category: "E-commerce Website",
      year: 2024,
      service: "Web Development, E-commerce Integration",
      client: "Pallash Boutique Store",
      duration: "Nov 2024 - Ongoing",
      url: "https://pallash.in/",
      features: [
        {fet:"Far curiosity incommode now led smallness allowance."},
        {fet:"Far curiosity incommode now led smallness allowance."},
        {fet:"Far curiosity incommode now led smallness allowance."},
        {fet:"Far curiosity incommode now led smallness allowance."},
        {fet:"Far curiosity incommode now led smallness allowance."},
        {fet:"Far curiosity incommode now led smallness allowance."},
        {fet:"Far curiosity incommode now led smallness allowance."},
      ],
      whatWeProvide: [
        {wwp:"Application design and Development"},
        {wwp:"Application design and Development"},
        {wwp:"Application design and Development"},
        {wwp:"Application design and Development"},
        {wwp:"Application design and Development"},
        {wwp:"Application design and Development"},
        {wwp:"Application design and Development"},
      ],
      solAndRes: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    },
    {
      id: 5,
      img: project1,
      title: "Pallash Boutique Store",
      project_description: "An elegant website developed for a boutique store to showcase their exclusive collection and drive online engagement.",
      category: "E-commerce Website",
      year: 2024,
      service: "Web Development, E-commerce Integration",
      client: "Pallash Boutique Store",
      duration: "Nov 2024 - Ongoing",
      url: "https://pallash.in/",
      features: [
        {fet:"Far curiosity incommode now led smallness allowance."},
        {fet:"Far curiosity incommode now led smallness allowance."},
        {fet:"Far curiosity incommode now led smallness allowance."},
        {fet:"Far curiosity incommode now led smallness allowance."},
        {fet:"Far curiosity incommode now led smallness allowance."},
        {fet:"Far curiosity incommode now led smallness allowance."},
        {fet:"Far curiosity incommode now led smallness allowance."},
      ],
      whatWeProvide: [
        {wwp:"Application design and Development"},
        {wwp:"Application design and Development"},
        {wwp:"Application design and Development"},
        {wwp:"Application design and Development"},
        {wwp:"Application design and Development"},
        {wwp:"Application design and Development"},
        {wwp:"Application design and Development"},
      ],
      solAndRes: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
      
    },
  ];

  return (
    <section className="gap our-projects">
      <div className="container">
        <div className="row">
          {projects.map((project) => (
            <div
              key={project.id}
              className={`col-lg-${project.id < 3 ? 6 : 4} col-md-6`}
              onClick={() => handleCardClick(project)}
            >
              <div className="projects-style">
                <div className="projects-img">
                  <figure>
                    <img src={project.img} alt="img" />
                  </figure>
                  <a>
                    <i className="fa-solid fa-angle-right"></i>
                  </a>
                </div>
                <h4>
                  <a>{project.title}</a>
                </h4>
                <span>
                  {project.category} — {project.year}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Client;
