import React from "react";
import "../../../assets/css/bootstrap.min.css";
import "../../../assets/css/style.css";
import "../../../assets/css/color.css";
import "../../../assets/css/responsive.css";
import "../../../assets/css/slick.css";
import "../../../assets/css/service.css";
import mockupImg from "../../../assets/img/mockup.jpg";
import dotsShaps from "../../../assets/img/shaps-1.png";
import videoThumbnail from "../../../assets/img/video-4.jpg";
import projectDetails1 from "../../../assets/img/project-details-1.jpg";
import projectDetails3 from "../../../assets/img/project-details-3.jpg";
import projectDetails4 from "../../../assets/img/project-details-4.jpg";
import projectDetails5 from "../../../assets/img/project-details-5.jpg";
import businessImage from "../../../assets/img/business-p.png";
import { Link } from "react-router-dom";


const ProjectDetails = ({project}) => {
  const features=project.features;
  // const whatWeProvide=project.whatWeProvide;
  return (
    <div>
    <section className="gap no-bottom">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="project-details">
              <ul>
                <li>
                  <a >{project.category}</a>,
                </li>
                
              </ul>
              <h4>{project.title}</h4>
            </div>
            <ul className="information-client">
              <li>
                <h4>Client:</h4>
                <span>{project.client}</span>
              </li>
              <li>
                <h4>date:</h4>
                <span>{project.duration}</span>
              </li>
              <li>
                <h4>Service:</h4>
                <span>{project.service}</span>
              </li>
              <li>
                <h4>Resolution:</h4>
                <span>High Resolution</span>
              </li>
            </ul>
            <div className="visit-website">
              <a href={project.url}>
                <i className="fa-solid fa-arrow-right"></i>Visit Website
              </a>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="mockup-img">
              <img src={mockupImg} alt="Mockup" />
              <img src={dotsShaps} alt="dots-shaps" className="shaps-1" />
            </div>
          </div>
          <div className="col-lg-12">
            <div className="overview pt-80">
              <h4>Overview</h4>
              <p>
                {project.project_description}
              </p>
            </div>
          </div>
          <div className="row pt-40 align-items-center">
            <div className="col-lg-6">
              <div className="overview">
                <h4>Features</h4>
                <ul className="features pt-0">{features.map((feature)=>(
                        <li>{feature.fet}</li>
                  ))}
                  
                  
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="sid-blog pb-lg-0">
                <div className="img-hover">
                  <figure>
                    <img
                      alt="Project Details"
                      className="w-100"
                      src={projectDetails1}
                    />
                  </figure>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12 pt-80">
            <div className="video position-relative">
              
              <img src={videoThumbnail} alt="Video Thumbnail" />
            </div>
          </div>
          <div className="col-lg-12">
            <div className="overview pt-80">
              <h4>Solution and Result</h4>
              <p>
                {project.solAndRes}
              </p>
            </div>
          </div>
          <div className="col-lg-12">
            
            <div className="row pt-5 project-details-two g-4 overview">
            <h3 className="text-black fw-bold fs-2">Other ongoing projects</h3>
              <div className="col-lg-4 col-md-6">
                <div className="sid-blog pb-lg-0">
                  <div className="img-hover">
                    <figure>
                      <img
                        alt="Project Details"
                        className="w-100"
                        src={projectDetails3}
                      />
                    </figure>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="sid-blog pb-lg-0">
                  <div className="img-hover">
                    <figure>
                      <img
                        alt="Project Details"
                        className="w-100"
                        src={projectDetails4}
                      />
                    </figure>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="sid-blog pb-0">
                  <div className="img-hover">
                    <figure>
                      <img
                        alt="Project Details"
                        className="w-100"
                        src={projectDetails5}
                      />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="mt-3">
      <div className="container">
        <div className="own-solutions">
          <div className="own-solutions-text">
            <h2>Own Solutions To Grow Your Business</h2>
            <p>
              Everything you need to accept card payments and grow your business
              anywhere
            </p>
            <Link to="/contact" className="btn">
              Get Started Now
            </Link>
          </div>
          <img src={businessImage} alt="business" />
        </div>
      </div>
    </section>
    </div>

  );
};

export default ProjectDetails;
