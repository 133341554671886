import React from 'react';
import BannerImage from '../../assets/img/bannr.png';

function Banner() {
  return (
    <section
    className="bannr">
   <div className="container">
      <div className="row align-items-end">
        <div className="col-lg-6">
          <div className="bannr-text">
            <h2>Services</h2>
            <p>
            Creating cutting-edge, world-class digital products, web designs, apps, and branding that inspire and engage.
            </p>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/">Home</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Tech Services
              </li>
            </ol>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="bannr-img">
            <img src={BannerImage} alt="img" />
          </div>
        </div>
      </div>
    </div>
  </section>
  
  )
}

export default Banner