import React from "react";
import ServiceMainHeading from "../ServiceMainHeading";
import SubServices from "../SubServices";
import DSvideo from "./../../../assets/video/DA.mp4";
import Header from "../../../component/Header";
import Banner from "../Banner";
import Footer from "../../../component/Footer";
import DATABRICS from "./../../../assets/img/service/DataService/databrics.jpg";
import SNOWFLAKE from "./../../../assets/img/service/DataService/snowflake.jpg";
import DBT from "./../../../assets/img/service/DataService/DBT.png";
import QLIK from "./../../../assets/img/service/DataService/qlik.png";
import FIVETRAN from "./../../../assets/img/service/DataService/fivetran.png";
import METALLION from "./../../../assets/img/service/DataService/matallion.jpg";
import AIRBYTE from "./../../../assets/img/service/DataService/airbyte.jpg";
import STICH from "./../../../assets/img/service/DataService/stich.jpg";
import PRIVACERA from "./../../../assets/img/service/DataService/Privacera.jpg";
import IMMUTA from "./../../../assets/img/service/DataService/immuta.jpg";
import COLABRA from "./../../../assets/img/service/DataService/collibra.jpg";
import CUSTOM from "./../../../assets/img/service/DataService/custom.jpg"

function DataServices() {
  const servicesArray = [
    {
      id: 1,
      name: 'Platform',
      details: 
      [
        { 
          detailId: 1, 
          title: "Databricks",
          imgurl: DATABRICS,
          description: "Databricks is specializing in data analytics and machine learning tools. They offer a unified analytics platform that integrates data engineering, data science, and machine learning workflows. Their solutions are designed to simplify and accelerate the process of managing and analyzing big data. Our team specializes in Databricks and in implementing solutions tailored to your needs in Databricks. Our experts are highly skilled in Databricks development."
        },
        { 
          detailId: 2, 
          title: "Snowflake",
          imgurl: SNOWFLAKE,
          description: "Snowflake is specializing in cloud-based data warehousing and analytics. They offer a platform that provides scalable storage, computing, and data sharing capabilities, designed to streamline data management and analytics. Our team specializes in Snowflake and in implementing solutions tailored to your needs in Snowflake. Our experts are highly skilled in Snowflake development."
        },
        { 
          detailId: 3, 
          title: "DBT",
          imgurl: DBT,
          description: "Specializing in DBT (Data Build Tool), delivering tailored solutions for seamless data transformation and analytics. Leveraging DBT's robust platform, raw data is transformed into actionable insights through a scalable, efficient, and version-controlled approach. Experts streamline workflows, enhance data quality, and create solutions precisely aligned with specific business objectives."
        },
        { 
          detailId: 4, 
          title: "Qlik",
          imgurl: QLIK,
          description: "Specializing in Qlik, providing tailored solutions for advanced data analytics and business intelligence. With expertise in Qlik's powerful tools like Qlik Sense and QlikView, solutions are designed to elevate data-driven decision-making and deliver impactful insights. Experts ensure seamless Qlik development, aligned with unique business needs."
        }
      ]
    },
    {
      id: 2,
      name: 'Accelator',
      details: 
      [
        { 
          detailId: 1, 
          title: "Fivetron",
          imgurl: FIVETRAN,
          description: "Fivetran is specializing in automated data integration. They offer a platform that simplifies the process of data extraction, loading, and transformation (ELT) by providing connectors for various data sources. Their solutions are designed to streamline data pipelines and ensure reliable data synchronization. Our team specializes in Fivetran and in implementing solutions tailored to your needs in Fivetran. Our experts are highly skilled in Fivetran development."
        },
        { 
          detailId: 2, 
          title: "Matallion",
          imgurl: METALLION,
          description: "Matillion is specializing in cloud-based data transformation. They offer a platform designed to integrate, transform, and load data into cloud data warehouses. Their tools are aimed at simplifying data workflows and accelerating analytics. Our team specializes in Matillion and in implementing solutions tailored to your needs in Matillion. Our experts are highly skilled in Matillion development."
        },
        { 
          detailId: 3, 
          title: "Airbyte",
          imgurl: AIRBYTE,
          description: "Airbyte is specializing in data integration. They offer a platform that provides connectors for various data sources to simplify the process of extracting, loading, and syncing data. Their solutions are designed to enhance data pipelines and ensure seamless data integration. Our team specializes in Airbyte and in implementing solutions tailored to your needs in Airbyte. Our experts are highly skilled in Airbyte development."
        },
        { 
          detailId: 4, 
          title: "Stich Data",
          imgurl: STICH,
          description: "Stitch is specializing in data integration and ETL (extract, transform, load) solutions. They offer a platform that simplifies data extraction and loading from various sources into data warehouses. Their tools are designed to streamline data pipelines and ensure reliable data synchronization. Our team specializes in Stitch and in implementing solutions tailored to your needs in Stitch. Our experts are highly skilled in Stitch development."
        }
      ]
    },{
      id: 1,
      name: 'Governance',
      details: 
      [
        { 
          detailId: 1, 
          title: "Privacera",
          imgurl: PRIVACERA,
          description: "Our team excels in Privacera, offering customized solutions for data governance, privacy management, and regulatory compliance. With deep expertise in Privacera development, we ensure robust data security, access control, and seamless integration across diverse data environments to meet your unique needs."
        },
        { 
          detailId: 2, 
          title: "Immuta",
          imgurl: IMMUTA,
          description: "Immuta is specializing in data governance and privacy. They offer a platform designed to automate data access controls, ensure compliance, and manage data privacy across various data environments. Their solutions aim to simplify data management and enhance security. Our team specializes in Immuta and in implementing solutions tailored to your needs in Immuta. Our experts are highly skilled in Immuta development."
        },
        { 
          detailId: 3, 
          title: "Collibra",
          imgurl: COLABRA,
          description: "Collibra is specializing in data governance and data management solutions. They offer a platform designed to provide comprehensive data stewardship, compliance, and quality management across organizations. Their solutions aim to enhance data visibility and governance. Our team specializes in Collibra and in implementing solutions tailored to your needs in Collibra. Our experts are highly skilled in Collibra development."
        },
        { 
          detailId: 4, 
          title: "Custom Serivces",
          imgurl: CUSTOM,
          description: "Our tailored IT solutions are designed to meet your unique business needs, whether it's custom software development, system integration, cybersecurity, or IT infrastructure optimization. We collaborate closely with you to deliver efficient and scalable solutions that drive growth and enhance performance. Partner with us for expert consulting and support, and transform your IT landscape today."
        }
      ]
    }
  ];
  
  return (
    <>
        <Header />
        <Banner />
        <div className="container my-5">
            <ServiceMainHeading title='Data Services' videourl={DSvideo}/>
            <SubServices services={servicesArray} />
        </div>
        <Footer />
    </>
  );
}

export default DataServices;
