import React from "react";
import ServiceMainHeading from "./../ServiceMainHeading";
import SubServices from "./../SubServices";
import cloudvideo from "./../../../assets/video/CI.mp4";
import Header from "../../../component/Header";
import Banner from "../Banner";
import Footer from "../../../component/Footer";
import AWS from "./../../../assets/img/service/cloud/aws.jpg";
import GCP from "./../../../assets/img/service/cloud/google.jpg";
import MS from "./../../../assets/img/service/cloud/azure.jpg";

function Cloud() {
  const servicesArray = [
    {
      id: 1,
      name: 'Our Cloud Capabilities ',
      details: 
      [
        { 
          detailId: 1, 
          title: "Amazon Web Services",
          imgurl: AWS,
          description: "Amazon Web Services (AWS) is specializing in cloud computing services. They offer a comprehensive suite of cloud solutions, including computing power, storage, databases, and analytics, designed to support a wide range of business needs. Their platform aims to provide scalable and flexible cloud infrastructure. Our team specializes in AWS and in implementing solutions tailored to your needs in AWS. Our experts are highly skilled in AWS development."
        },
        { 
          detailId: 2, 
          title: "Google Cloud Platform",
          imgurl: GCP,
          description: "Google Cloud is specializing in cloud computing services. They offer a range of solutions, including computing power, storage, databases, and data analytics, designed to support diverse business needs. Their platform aims to provide scalable, flexible, and secure cloud infrastructure. Our team specializes in Google Cloud and in implementing solutions tailored to your needs in Google Cloud. Our experts are highly skilled in Google Cloud development."
        },
        { 
          detailId: 3, 
          title: "Microsoft Azure",
          imgurl: MS,
          description: "Microsoft Azure is specializing in cloud computing services. They offer a broad range of solutions, including computing power, storage, databases, and analytics, designed to meet various business needs. Their platform aims to provide scalable, flexible, and secure cloud infrastructure. Our team specializes in Azure and in implementing solutions tailored to your needs in Azure. Our experts are highly skilled in Azure development."
        }
      ]
    }
  ];
  
  return (
    <>
        <Header />
        <Banner />
        <div className="container my-5">
            <ServiceMainHeading title='Cloud' videourl={cloudvideo}/>
            <SubServices services={servicesArray} />
        </div>
        <Footer />
    </>
  );
}

export default Cloud;
