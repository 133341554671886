import React from "react";
import "./about.css";
import AboutVideo from "./../../assets/video/About.mp4";

function AboutUs() {
  return (
    <section className="section_all bg-light" id="about">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section_title_all text-center">
              <h3 className="font-weight-bold">
                IT Sight <span className="text-custom">Welcome's You!</span>
              </h3>
              {/* <p className="section_subtitle mx-auto text-muted">Lorem Ipsum is simply dummy text of the printing and typesetting industry. <br />Lorem Ipsum has been the industry's standard dummy text.</p> */}
              <div>
                <i className=""></i>
              </div>
            </div>
          </div>
        </div>

        <div className="row vertical_content_manage mt-5">
          <div className="col-lg-6">
            <AboutContent />
          </div>
          <div className="col-lg-6">
            <div className="img_about mt-3">
              <video autoPlay muted loop width="100%">
                <source src={AboutVideo} type="video/mp4" />
              </video>
            </div>
          </div>
        </div>

        <div className="row mt-3">
          {/* <h4 className="about_heading text-capitalize font-weight-bold mt-4 text-center ">Lorem Ipsum is simply dummy text of the printing industry.</h4> */}
          <div className="col-lg-4">
            <AboutDetail iconClass="fa-solid fa-brain" title="PRODUCT" />
          </div>
          <div className="col-lg-4">
            <AboutDetail iconClass="fa-solid fa-headset" title="SERVICES" />
          </div>
          <div className="col-lg-4">
            <AboutDetail iconClass="fas fa-paper-plane" title="CONSULTING" />
          </div>
          {/* <div className="col-lg-4">
                        <AboutDetail iconClass="fas fa-pencil-alt" title="Creative Design" description="Lorem Ipsum is simply dummy text of the printing and typesetting industry." />
                    </div>
                    <div className="col-lg-4">
                        <AboutDetail iconClass="fab fa-angellist" title="We make Best Result" description="Lorem Ipsum is simply dummy text of the printing and typesetting industry." />
                    </div>
                    <div className="col-lg-4">
                        <AboutDetail iconClass="fas fa-paper-plane" title="Best Platform" description="Lorem Ipsum is simply dummy text of the printing and typesetting industry." />
                    </div> */}
        </div>
      </div>
    </section>
  );
}

function AboutContent() {
  return (
    <div className="about_header_main mt-3">
      <div className="about_icon_box">
        <p
          className="text_custom font-weight-bold "
          style={{ fontSize: "30px" }}
        >
          Transforming Visions Into Digital Realities
        </p>
      </div>
      {/* <h4 className="about_heading text-capitalize font-weight-bold mt-4">Lorem Ipsum is simply dummy text of the printing industry.</h4> */}
      <p className="text-muted mt-3">
        {" "}
        Welcome to IT Sight, where innovation meets expertise in Data, Cloud,
        and AI software solutions! As a pioneering leader in the industry, we
        empower businesses worldwide to harness the full potential of their data
        to drive growth and innovation. At IT Sight, we offer a comprehensive
        suite of products and services designed to streamline data management,
        optimize cloud infrastructure, and leverage cutting-edge AI
        technologies. Our diverse range of offerings caters to businesses of all
        sizes and across various industries, ensuring that each client receives
        tailored solutions to meet their unique needs and challenges.
      </p>
      {/* <p className="text-muted mt-3"> Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage.</p> */}
    </div>
  );
}

function AboutDetail({ iconClass, title, description }) {
  return (
    <div className="about_content_box_all mt-3">
      <div className="about_detail text-center">
        <div className="about_icon">
          <i className={iconClass}></i>
        </div>
        <h5 className="text-dark text-capitalize mt-3 font-weight-bold">
          {title}
        </h5>
        <p className="edu_desc mt-3 mb-0 text-muted">{description}</p>
      </div>
    </div>
  );
}

export default AboutUs;
