import React from "react";
import Header from "../../component/Header";
import Footer from "../../component/Footer";
import Banner from "./Banner";
import Cards from "./Cards";
import "./card.css";

import health from "./../../assets/img/Business/medical-banner-with-doctor-wearing-goggles.jpg";
import Telecom from "./../../assets/img/Business/Telecom.jpg";
import Industry from "./../../assets/img/Business/Industry.jpg";
import Banking from "./../../assets/img/Business/Banking.jpg";

const cardData = [
  {
    id: 1,
    title: "Healthcare",
    subtitle: "",
    description:
      "The healthcare industry is undergoing a digital transformation, driven by technologies like electronic health records, telemedicine, AI, IoT, blockchain, and cloud computing. These advancements offer improved patient outcomes, increased efficiency, enhanced data security, reduced costs, and accelerated research. However, challenges such as data privacy, interoperability, and workforce readiness must be addressed to fully realize the benefits of digital healthcare.",
    imgUrl: health,
  },
  {
    id: 2,
    title: "Banking & Financial",
    subtitle: "",
    description:
      "The banking and financial services industry is undergoing a digital revolution, driven by innovations like online banking, mobile payments, AI, and blockchain. While these advancements offer numerous benefits, challenges such as cybersecurity and regulatory compliance must be addressed to ensure a sustainable digital future.",
    imgUrl: Banking,
  },
  {
    id: 3,
    title: "Telecom & Media",
    subtitle: "",
    description:
      "The telecom and media industries are converging, driven by 5G, streaming, AI, and IoT. These advancements offer enhanced connectivity, immersive experiences, personalized content, increased efficiency, and innovation. However, challenges such as network infrastructure, content rights, cybersecurity, and competition must be addressed to ensure a successful digital future.",
    imgUrl: Telecom,
  },
  {
    id: 4,
    title: "Industrial",
    subtitle: "",
    description:
      "The Industrial Internet of Things (IIoT) is transforming manufacturing by connecting devices and sensors to the internet. This enables data-driven decision making, increased efficiency, improved quality, predictive maintenance, and enhanced productivity. However, challenges such as data security, interoperability, scalability, and workforce readiness must be addressed to fully realize the benefits of IIoT.",
    imgUrl: Industry,
  },
];

function IndustryMain() {
  return (
    <div>
      <Header />
      <Banner />
      <Cards data={cardData} />
      <Footer />
    </div>
  );
}

export default IndustryMain;
