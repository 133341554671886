import React from 'react';
import subscribeicon from '../assets/img/subscribe-icon.png';
import shape1 from '../assets/img/dots-shaps.png';
import shape2 from '../assets/img/shaps-1.png';
import logo from '../assets/img/logo.png';
import './footer.css';

function Footer() {
  return (
    <footer>
      <div className="container">
        <div className="row align-items-center pb">
          <div className="col-lg-6">
            <div className="subscribe-text">
              <img src={subscribeicon} alt="subscribe-icon" />
              <div>
                <h2>Sign up to our newsletter</h2>
                <p>get recent content and updates.</p>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <form className="subscribe">
              <input
                type="text"
                name="Enter Your Email"
                placeholder="Enter Your Email Address..."
              />
              <button className="btn">Subscribe</button>
            </form>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div className="information">
              <span>Drop us an email</span>
              <a href="mailto:username@domain.com">contact@itsight.in</a>
              <div className="d-flex">
                <i className="fa-solid fa-location-dot" />
                <h6>
                  Magarpatta City, Pune, MH, 411028, India.
                </h6>
              </div>
              <ul className="social-icon">
                <li>
                  <a href="https://www.facebook.com/itsightin">
                    <i className="fa-brands fa-facebook-f" />
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/itsightin">
                    <i className="fa-brands fa-twitter" />
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/company/itsightin/">
                    <i className="fa-brands fa-linkedin" />
                  </a>
                </li>
                <li>
                  <a href="https://www.youtube.com/@itsightin">
                    <i className="fa-brands fa-youtube" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="footer-links">
              <h3>Tech Services</h3>
              <div className="boder" />
              <ul>
              <li>
                  <i className="fa-solid fa-angle-right" />
                  <a href="/cloud">Cloud & Infrastructure</a>
                </li>

                <li>
                  <i className="fa-solid fa-angle-right" />
                  <a href="/dataservice">Data Services</a>
                </li>
                <li>
                  <i className="fa-solid fa-angle-right" />
                  <a href="/aiml">Artificial Intelligence</a>
                </li>
                <li>
                  <i className="fa-solid fa-angle-right" />
                  <a href="/aiml">Machine Learning</a>
                </li>
               
                

              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="footer-links">
              <h3>Business Industries</h3>
              <div className="businessservice" />
              <ul>
                <li>
                  <i className="fa-solid fa-angle-right" />
                  <a href="businessservice">Healthcare</a>
                </li>
                <li>
                  <i className="fa-solid fa-angle-right" />
                  <a href="businessservice">Banking & Financial</a>
                </li>
                <li>
                  <i className="fa-solid fa-angle-right" />
                  <a href="businessservice">Telecom & Media</a>
                </li>
                <li>
                  <i className="fa-solid fa-angle-right" />
                  <a href="businessservice">Industrial</a>
                </li>
                {/* <li>
              <i className="fa-solid fa-angle-right" />
              <a href="javascript:void(0)">App Download</a>
            </li>
            <li>
              <i className="fa-solid fa-angle-right" />
              <a href="javascript:void(0)">How It Works</a>
            </li>
            <li>
              <i className="fa-solid fa-angle-right" />
              <a href="javascript:void(0)">Terms &amp; Conditions</a>
            </li> */}
              </ul>
            </div>
          </div>
        </div>
        <div className="copyrights">
          <div className='row align-items-center justify-items-center m-auto'>
            <div className='col-md-4'>
              <a href="/">
                <img src={logo} alt="logo" style={{ width: '130px' }} />
              </a>
            </div>
            <div className='col-md-4'>
              <span>
                <a href="https://chat.whatsapp.com/Ir5alYhx1I56zATOQY09oX">Join On WhatsApp</a>
              </span>
            </div>
            <div className="col-md-4">
              <p>
                Copyright © <a href="/">IT SIGHT</a> 2024. All Rights Reserved.
              </p>
            </div>
          </div>

        </div>
        <img
          src={shape1}
          alt="dots-shaps"
          className="dots-shaps"
        />
        <img src={shape2} alt="dots-shaps" className="shaps-1" />
      </div>
    </footer>

  )
}

export default Footer;
